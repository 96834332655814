import { Loading } from 'element-ui';
import moment from 'moment';
/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
	let result = '';
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + '=';
		if (value !== null && typeof value !== 'undefined') {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof value[key] !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + '=';
						result += subPart + encodeURIComponent(value[key]) + '&';
					}
				}
			} else {
				result += part + encodeURIComponent(value) + '&';
			}
		}
	}
	return result;
}
/**
 * 页面请求global loading
 */
let loading = null;
const startLoading = () => {
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.7)',
	});
};
const endLoading = () => {
	loading.close();
};
// 合并同一页面多个请求触发loading
let needLoadingRequestCount = 0;

export function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading();
	}
	needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return;
	needLoadingRequestCount--;
	if (needLoadingRequestCount === 0) {
		endLoading();
	}
}

// 去除url参数部分
export function changeUrl(url) {
	if (url.indexOf('?') !== -1) {
		return url.split('?')[0];
	} else {
		return url;
	}
}
// 处理文件名
export function safeObjectName(pageName, type, objectName) {
	return (
		pageName +
		"_" +
		type +
		"/" +
		Math.round(new Date().getTime() / 1000) + Math.floor(Math.round(Math.random() * 100 + 1)) +
		objectName.substr(objectName.lastIndexOf("."))
	);
}
// 获取时间差
export function difftime(times) {
	const now = moment().format('YYYY-MM-DD HH:mm:ss');
	const seconds = moment(now).diff(times, 'seconds');
	const minutes = moment(now).diff(times, 'minutes');
	const hours = moment(now).diff(times, 'hours');
	const days = moment(now).diff(times, 'days');
	const months = moment(now).diff(times, 'months');
	const years = moment(now).diff(times, 'years');
	if (seconds <= 60 && seconds != 0) {
		return `${seconds}秒前`;
	} else if (minutes <= 60 && minutes != 0) {
		return `${minutes}分钟前`;
	} else if (hours <= 24 && hours != 0) {
		return `${hours}小时前`;
	} else if (days <= 30 && days != 0) {
		return `${days}天前`;
	} else if (months <= 12 && months != 0) {
		return `${months}月前`;
	} else if (years > 0) {
		return `${years}年前`;
	}
}

/**
 * 页面滚动到底部触发监听,执行回调
 * @param callBack
 */
export function scrollToBottom(callBack) {
	window.onscroll = () => {
		let scrollTop = 0;
		let bodyScrollTop = 0;
		let documentScrollTop = 0;
		if (document.body) {
			bodyScrollTop = document.body.scrollTop;
		}
		if (document.documentElement) {
			documentScrollTop = document.documentElement.scrollTop;
		}
		scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
		let scrollHeight = 0;
		let bodyScrollHeight = 0;
		let documentScrollHeight = 0;
		if (document.body) {
			bodyScrollHeight = document.body.scrollHeight;
		}
		if (document.documentElement) {
			documentScrollHeight = document.documentElement.scrollHeight;
		}
		// 获取文档元素的内容垂直滚动的像素数
		scrollHeight = bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;
		let windowHeight = 0;
		// 判断当前文档的渲染模式是混杂模式还是"标准模式"
		if (document.compatMode === 'CSS1Compat') {
			// “标准模式”或者“准标准模式(almost standards mode)”
			windowHeight = document.documentElement.clientHeight;
		} else {
			// 混杂模式,值为"BackCompat"
			windowHeight = document.body.clientHeight;
		}
		// 若文档内容垂直滚动像素 + 当前窗口高度的像素 === document.body.scrollHeight或document.documentElement.scrollHeight返回Promise对象，执行后续操作
		if (Math.round(scrollTop + windowHeight) === scrollHeight) {
			callBack();
		}
	};
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
	url = url == null ? window.location.href : url;
	const search = url.substring(url.lastIndexOf('?') + 1);
	const obj = {};
	const reg = /([^?&=]+)=([^?&=]*)/g;
	search.replace(reg, (rs, $1, $2) => {
		const name = decodeURIComponent($1);
		let val = decodeURIComponent($2);
		val = String(val);
		obj[name] = val;
		return rs;
	});
	return obj;
}

/**
 * 跳转项目内路由
 * @param {String} path 
 * @param {Object} params 
 */
export function goPageRoute(path, params) {
	this.$router.push({ path, query: params });
}