import request from '@/utils/request';

// 轮播图 -- 获取轮播图列表
export function getUseableList(data) {
  return request({
    url: '/operation/carousel/getUseableList',
    method: 'get',
    params: data,
    requestBaseUrl: 'asvc'
  });
}

// 获取云存储配置
export function getCosProperties(data) {
  return request({
    url: '/htuc/v1/system/cos/getCosProperties',
    method: 'get',
  });
}
//邮箱地址
export function getEmail(data) {
  return request({
    url: '/system/email',
    method: 'post',
    data: data,
    requestBaseUrl: 'asvc'
  });
}
//发送短信验证
export function captchaMessage(data) {
  return request({
    url: `/htuc/v1/entauth/apply/captchaMessage?phone=${data.phone}`,
    method: 'get',
    data: data
  });
}
//发送试用申请
export function sendApply(data) {
  return request({
    url: '/htuc/v1/entauth/sendApply',
    method: 'post',
    data: data
  });
}