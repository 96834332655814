import Vue from "vue";
import { Point } from "@/api/maidian";
// 埋点指令
let track = Vue.directive("track", {
    bind: (el, binding, vnode) => {

        if (binding.value && binding.value.act) {
            switch (binding.value.act) {
                case "browse":
                case "click":
                    el.onclick = function() {
                        // 在此插入埋点数据(调用API)
                        // console.log(binding.value);
                        const { act, node } = binding.value;
                        Point(node).then((res) => {
                        });
                    };
                    break;
            }
        }
    },
});
export default track;